<template>
  <div class="d-flex z-1000 flex-column w-100 h-100 font-rem-1-2 rounded">
    <div
      v-if="!this.readySize"
      class="
        position-fixed
        w-100
        h-100
        bg-gray-75
        d-flex
        align-items-center
        justify-content-center
        p-0-0-0-0
        z-8000
      "
    >
      <inline-svg
        src="/rings.svg"
        width="180px"
        height="180px"
        fill="white"
        aria-label="Loading..."
      />
    </div>
    <div v-show="this.readyMap">
      <div style="height: 85vh; width: 100%'">
        <div id="map-wrap" class="h-100">
          <l-map
            ref="myMap"
            :options="{ zoomControl: false }"
            v-show="this.readySize"
            :zoom.sync="zoom"
            :center="centerInter"
            @ready="doSomethingOnReady()"
            @click="clickMap"
          >
            <!-- <l-map
        ref="myMap"
        :zoom.sync="zoom"
        :center="[position.latitude, position.longitude]"
        @ready="doSomethingOnReady()"
        @click="clickMap"
      > -->
            <l-tile-layer
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            ></l-tile-layer>
            <!-- <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        ></l-tile-layer> -->
            <l-marker
              v-if="customMarket.latitude && customMarket.longitude"
              :lat-lng="[customMarket.latitude, customMarket.longitude]"
            ></l-marker>
          </l-map>
        </div>
      </div>
      <div
        style="height: 15vh"
        class="
          d-flex
          w-100
          z-1000
          justify-content-around
          align-items-center
          bg-white
        "
      >
        <inline-svg
          class="flex-grow-1"
          src="/cancel-icon.svg"
          fill="black"
          width="40px"
          height="40px"
          aria-label="Loading..."
          @click="$emit('closeMap')"
        />
        <div class="vl"></div>
        <inline-svg
          class="flex-grow-1"
          src="/center-icon.svg"
          fill="black"
          width="40px"
          height="40px"
          aria-label="Loading..."
          @click="center"
        />
        <div class="vl"></div>
        <inline-svg
          class="flex-grow-1"
          src="/check-icon.svg"
          fill="black"
          width="45px"
          height="45px"
          aria-label="Loading..."
          @click="setNewLocation"
        />
      </div>
    </div>
  </div>
</template>

<script>
//import L from "leaflet"
import { mapMutations } from "vuex"
import geoLocalization from "@/mixins/geoLocalization"
import { LMap, LTileLayer, LMarker } from "vue2-leaflet"
import { latLng /* , Icon, icon */ } from "leaflet"
export default {
  props: ["box", "showMap"],
  data() {
    return {
      readyMap: false,
      readySize: false,
      centerInter: [4.822786, -74.046872],
      map: null,
      mapApi: null,
      latLng,
      zoom: 13,
      draggable: false,
      popupContent: "KLaius",
      searching: false,
      iconSize: 70,
      searchResult: [],
      geoPosition: null,
      customMarket: {
        /*  latitude: 1.1,
        longitude: 1.2 */
      },
      distance: 1000
    }
  },
  mixins: [geoLocalization],
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  computed: {
    centerComputed() {
      if (!this.customMarket.latitude) {
        return [this.position.latitude, this.position.longitude]
      } else {
        return [this.customMarket.latitude, this.customMarket.longitude]
      }
    }
  },
  methods: {
    ...mapMutations(["LOADING"]),
    setNewLocation() {
      this.$emit("setLocation", this.geoPosition)
    },
    async center() {
      this.LOADING(true)
      const navigatorApiRes = await this.navAPi()
      console.log("nav response", navigatorApiRes, this.$refs.myMap.mapObject)
      //this.zoom = 10
      const { latitude, longitude } = navigatorApiRes.value
      this.customMarket = {
        latitude,
        longitude
      }
      this.geoPosition = navigatorApiRes.value
      this.LOADING(false)
      this.map.flyTo([this.customMarket.latitude, this.customMarket.longitude])
    },
    doSomethingOnReady() {
      this.map = this.$refs.myMap.mapObject
      this.mapApi = this.$refs.myMap
      console.log("HEEllo map", this.$refs.myMap, this.map)
      if (this.box.geoReference) {
        const { latitude, longitude } = this.box.geoReference
        this.geoPosition = this.box.geoReference
        this.customMarket = {
          latitude,
          longitude
        }
      }
      if (this.customMarket.latitude) {
        this.centerInter = [
          this.customMarket.latitude,
          this.customMarket.longitude
        ]
      }
      this.readyMap = true
      this.readySize = true
      setTimeout(() => {
        this.map.invalidateSize()
      }, 20)
    },
    clickMap(v) {
      console.log("Click", v)
      const { latlng } = v
      if (latlng && latlng.lat && latlng.lng) {
        // se actualiza el store con esta info
        const payload = {
          field: "geoReference",

          value: {
            latitude: latlng.lat,
            longitude: latlng.lng,
            point: {
              type: "Point",
              coordinates: [latlng.lng, latlng.lat]
            }
          }
        }
        this.customMarket = {
          latitude: latlng.lat,
          longitude: latlng.lng
        }
        this.geoPosition = payload.value
      }
    }
  }
}
</script>

<style scoped>
.vl {
  border-left: 0.08rem solid #b7b7b7;
  height: 90px;
  max-width: 1px;
}
</style>
